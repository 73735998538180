// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import styled from "styled-components"
import Logo from "../images/haarkonzept-logo.svg"
import HeaderImage from "./header-image"


const Header = ({ siteTitle }) => (
  <header>

        <div className="logo">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <a href="/">
                            <Logo />
                        </a>                        
                    </div>
                </div>
            </div>
            
        </div>

      <HeaderImage />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
